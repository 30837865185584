  //START BROWSER DETECTION
  var browserType = "";
  browserType += (bowser.chrome == undefined ? '' : "chrome");
  browserType += (bowser.firefox == undefined ? '' : "firefox");
  browserType += (bowser.msedge == undefined ? '' : "edge");
  browserType += (bowser.msie == undefined ? '' : "ie");
  browserType += (bowser.safari == undefined ? '' : "safari");
  browserType += (bowser.opera == undefined ? '' : "opera");
  jQuery("html").addClass(browserType);
  jQuery("html").addClass(browserType + "-" + bowser.version);

  function disableScroll() {
    if (window.addEventListener) // older FF
      window.addEventListener('DOMMouseScroll', preventDefault, false);
    window.onwheel = preventDefault; // modern standard
    window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
    window.ontouchmove = preventDefault; // mobile
    document.onkeydown = preventDefaultForScrollKeys;
  }
  function getServerTime() {
    return (new Date).getTime()
  }

  function updateTime() {
    var e = new Date(getServerTime());
    $("#vola_clock").html(ro(e.getHours()) + ":" + ro(e.getMinutes()) + ":" + ro(e.getSeconds()) + "<span>." + d(e.getMilliseconds()) + "</span>")
  }

  function ro(e) {
    if (e < 10) return "0" + e;
    return e
  }

  function d(e) {
    if (e < 100 || e > 900)
      return 0;
    return (e / 100).toPrecision(1)
  }

  jQuery(document).ready(function ($) {

    $("#pie_register #user_login").attr("placeholder", "Email o Username");
    $("#pie_register #user_pass").attr("placeholder", "Password");
    $("#piereg_lostpasswordform #user_login").attr("placeholder", "Inserisci Email");
    
    var minHeight = 0;
    if( $(window).width() > 1000 ){
      $(".column_event").each(function (ind, val) {
        if ($(val).height() > minHeight) {
          minHeight = $(val).height();
        }
      });
      $(".column_event").each(function (ind, val) {
        $(val).height(minHeight);
      });

      $(".column_event .image_container").each(function (ind, val) {
        if ($(val).height() > minHeight) {
          minHeight = $(val).height();
        }
      });
      $(".column_event .image_container").each(function (ind, val) {
        $(val).height(minHeight);
      });

    }

    setInterval('updateTime();', 100);
  });


